<template>
  <div>
    <b-link :to="{ name: 'addCustomer' }">Ajouter un client</b-link>
    <b-row>
      <b-col>
        <b-input-group>
          <b-form-input
              v-model="searchedTerm"
              placeholder="Recherche"
              @keyup.enter="searchCustomersByTerm"
          />
          <b-input-group-append>
            <b-button
                variant="outline-primary"
                @click="searchCustomersByTerm"
            >
              <font-awesome-icon icon="search"/>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col v-if="customers.length > 0">
        <b-pagination
            class="float-right"
            v-model="currentPage"
            :total-rows="total"
            :per-page="pageSize"
            @change="loadCustomers"
        />
      </b-col>
    </b-row>
    <b-row class="my-2">
      <b-col v-if="loading">
        <CustomerListSkeleton/>
      </b-col>
      <b-col v-else-if="customers.length > 0">
        <CustomerList :customers="customers"/>
      </b-col>
      <b-col v-else>
        <span>Aucun client ne correspond à votre recherche</span>
      </b-col>
    </b-row>
    <b-row v-if="customers.length > 0">
      <b-col>
        <b-pagination
            class="float-right"
            v-model="currentPage"
            :total-rows="total"
            :per-page="pageSize"
            @change="loadCustomers"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapState} from "vuex";

import CustomerList from "@/components/customers/CustomerList";
import CustomerListSkeleton from "@/components/customers/CustomerListSkeleton.js";

export default {
  data() {
    return {
      currentPage: 1,
      loading: false,
      searchedTerm: null,
    };
  },
  computed: {
    ...mapState({
      customers: (state) => state.customers.all,
      total: (state) => state.customers.total,
      pageSize: (state) => state.customers.pageSize,
    }),
  },
  components: {
    CustomerList,
    CustomerListSkeleton,
  },
  async created() {
    await this.loadCustomers();
  },
  methods: {
    async loadCustomers(page = 1) {
      this.loading = true;
      try {
        await this.$store.dispatch("customers/fetchCustomers", {
          page,
          search: this.searchedTerm ?? null,
        });
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title: "Une erreur est survenue lors de la récupération des clients",
          text: e.message,
        });
        return;
      }
      this.loading = false;
    },
    searchCustomersByTerm() {
      this.currentPage = 1;
      this.loadCustomers(this.currentPage);
    },
  },
};
</script>

