<template>
  <b-table
      hover
      striped
      borderless
      head-variant="light"
      :items="customers"
      :fields="fields"
  >
    <template #cell(fullname)="customer">
      {{ customer.item.lastName.toUpperCase() }} {{ customer.item.firstName }}
    </template>

    <template #cell(edit)="customer">
      <div class="row justify-content-end mr-0">
        <EditDeleteButtonGroup
            @edit="editCustomer(customer.item)"
            @delete="deleteCustomer(customer.item)"
        />
      </div>
    </template>
  </b-table>
</template>

<script>
import EditDeleteButtonGroup from "@/components/buttons/EditDeleteButtonGroup";

export default {
  props: {
    customers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {key: "fullname", label: "NOM Prénom"},
        {key: "email", label: "Email"},
        {key: "edit", label: ""},
      ],
    };
  },
  methods: {
    editCustomer(customer) {
      this.$router.push({
        name: "customer",
        params: {uid: customer.uid},
      });
    },
    async deleteCustomer(customer) {
      try {
        await this.$store.dispatch("customers/deleteCustomer", customer.uid);
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title: "Une erreur est survenue lors de la suppression du client",
          text: e.message,
        });
        return;
      }

      await this.$swal.fire({
        icon: "success",
        title: "Client supprimé",
      });
    },
  },
  components: {
    EditDeleteButtonGroup,
  },
};
</script>
